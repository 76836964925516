import { setCookie } from './cookies'

export function setupLanguageSwitcher() {
  const languageSwitch = document.querySelector<HTMLElement>(
    '.js-language-switch',
  )
  if (languageSwitch) {
    document.body.addEventListener('click', e => {
      const target = e.target as HTMLElement
      if (!languageSwitch.contains(target)) {
        languageSwitch.classList.remove('is-open')
      }
    })
    const highlightActiveLanguage = () => {
      const items = languageSwitch.querySelectorAll<HTMLElement>('[data-lang]')
      const { currentLang = 'en' } = languageSwitch.dataset
      for (const item of items) {
        if (item.dataset.lang === currentLang) {
          item.classList.add('is-active')
        } else {
          item.classList.remove('is-active')
        }
      }
    }
    languageSwitch.addEventListener('click', event => {
      const target = event.currentTarget as HTMLElement
      const item = event.srcElement as HTMLElement
      const { lang } = item.dataset
      if (target) {
        if (lang) {
          setCookie('lang', lang)
          target.classList.remove('is-open')
        } else {
          highlightActiveLanguage()
          target.classList.toggle('is-open')
        }
      }
    })
  }
}
