export function getCookie(name: string) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return v ? v[2] : null
}
export function setCookie(
  name: string,
  value: string | number,
  minutes: number = 5265000,
) {
  const d = new Date()
  d.setTime(d.getTime() + 60 * 1000 * minutes)
  document.cookie = `${name}=${value};domain=.jottacloud.com;path=/;expires=${d.toUTCString()}`
}
