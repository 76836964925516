export interface Swipe {
  next: () => void
  prev: () => void
  toString(): string
}
export interface SwipeConstructor {
  new (el: HTMLElement): Swipe
}
declare global {
  interface Window {
    Swipe: SwipeConstructor
  }
}
export function setupCarousel() {
  const carouselEl = document.getElementById('carousel')
  if (carouselEl) {
    const mySwipe = new window.Swipe(carouselEl)
    const nextButton = carouselEl.querySelector<HTMLElement>('.carousel-next')
    const prevButton = carouselEl.querySelector<HTMLElement>('.carousel-prev')
    const titles = carouselEl.querySelectorAll('.carousel-text h6')

    if (prevButton && nextButton) {
      let index = 0
      const setButtonTitlesForIndex = (idx: number) => {
        if (idx === 0) {
          prevButton.style.visibility = 'hidden'
        } else {
          prevButton.innerHTML = `<span class="prev-caret">&#9668;</span>${
            titles[idx - 1].innerHTML
          }`
          prevButton.style.visibility = 'visible'
        }

        if (idx === 13) {
          nextButton.style.visibility = 'hidden'
        } else {
          nextButton.style.visibility = 'visible'
          nextButton.innerHTML = `${
            titles[idx + 1].innerHTML
          }<span class="next-caret">&#9658;</span>`
        }
      }
      prevButton.addEventListener('click', () => {
        if (index > 0) {
          index--
          mySwipe.prev()
          setButtonTitlesForIndex(index)
        }
      })
      nextButton.addEventListener('click', () => {
        if (index > 0) {
          index--
          mySwipe.prev()
          setButtonTitlesForIndex(index)
        }
      })
    }
  }
}
