export function setupHamburger() {
  // small screen menu toggle
  const siteHeader = document.getElementById('site-header')
  const toggleNav = document.querySelector<HTMLButtonElement>('.js-toggleNav')
  const navSections = Array.from(
    document.querySelectorAll<HTMLElement>('.site-header__nav__section'),
  )
  if (toggleNav && navSections && siteHeader) {
    const isExpanded = () => siteHeader.classList.contains('is-expanded')
    const isClickOutside = (el: HTMLElement) =>
      navSections.every(nav => !(el === nav) || !nav.contains(el))
    document.body.addEventListener('click', e => {
      const clickedElement = e.target as HTMLElement
      if (clickedElement && isExpanded() && isClickOutside(clickedElement)) {
        siteHeader.classList.remove('is-expanded')
        toggleNav.classList.add('is-closed')
      }
    })
    toggleNav.addEventListener('click', e => {
      e.preventDefault()
      e.stopPropagation()
      toggleNav.classList.toggle('is-closed')
      siteHeader.classList.toggle('is-expanded')
    })
  }
}
