import { ImageRow } from './ImageRow'

export function setupFrontpageImageRow() {
  const imageRow = document.getElementById('image-row')
  if (imageRow) {
    const rowTpl = `<div class="image-row__block image-row__block--large">
      <div class="image-row__placeholder"></div>
    </div>`
    const smallScreen = document.documentElement.clientWidth <= 650
    const rowLength = smallScreen ? 4 : 10
    const html = `<div class="image-row__column">${rowTpl.repeat(
      rowLength,
    )}</div>`
    imageRow.insertAdjacentHTML('afterbegin', html)
    if (!smallScreen) {
      imageRow.insertAdjacentHTML('afterbegin', html)
    }
    new ImageRow({
      sizes: {
        default: 288,
        small: 210,
      },
      sizesAttr: '(max-width: 43.75em) 210px, 288px',
    }).init()
  }
}
export function setupPhotoImageRow() {
  const imageRow = document.getElementById('image-row')
  if (imageRow) {
    const rowTpl = `<div class="image-row__block image-row__block--small">
      <div class="image-row__placeholder"></div>
    </div>`
    const width = imageRow.getBoundingClientRect().width
    const rowLength = Math.ceil(width / (width > 700 ? 225 : 105)) + 1

    const html = `<div class="image-row__column">${rowTpl.repeat(
      rowLength,
    )}</div>`

    imageRow.insertAdjacentHTML('afterbegin', html)

    new ImageRow({
      sizes: {
        default: 225,
        small: 105,
      },
      sizesAttr: '(max-width: 43.75em) 105px, 225px',
    }).init()
  }
}
