export function setupPhotoPromo() {
  // only load in phone image if there is space
  if (document.documentElement.clientWidth >= 1000) {
    const photoPromoEl = document.getElementById('photo-promo')
    if (photoPromoEl) {
      const basePath = '/resources/images/home/'
      const image = document.createElement('img')
      image.src = `${basePath}photo-promo.png`
      image.srcset = `${basePath}photo-promo.png, ${basePath}photo-promo@2x.png 2x`
      image.className = 'photos-promo__image'
      image.alt = ''

      photoPromoEl.appendChild(image)
    }
  }
}
