const draggable = Array.from<HTMLElement>(
  document.querySelectorAll('.js-draggable'),
)

class DraggableHandler {
  private el: HTMLElement
  private pos = { top: 0, left: 0, x: 0, y: 0 }
  private dm = 0

  constructor(el: HTMLElement) {
    this.el = el
    this.mouseUp = this.mouseUp.bind(this)
    this.mouseDown = this.mouseDown.bind(this)
    this.mouseMove = this.mouseMove.bind(this)
    this.mouseClick = this.mouseClick.bind(this)
    el.addEventListener('mousedown', this.mouseDown)
    el.setAttribute('draggable', 'false')
  }

  private mouseUp(e: MouseEvent) {
    document.removeEventListener('mousemove', this.mouseMove)
    document.removeEventListener('mouseup', this.mouseUp)
    this.el.style.cursor = ''
    this.el.style.userSelect = ''

    for (const el of this.el.children) {
      ;(el as HTMLElement).style.cursor = ''
    }
  }

  private mouseClick(e: MouseEvent) {
    if (this.dm >= 10) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.dm = 0
    this.el.removeEventListener('click', this.mouseClick)
  }

  private mouseDown(e: MouseEvent) {
    e.preventDefault()
    this.el.style.cursor = 'grabbing'
    this.el.style.userSelect = 'none'

    for (const el of this.el.children) {
      ;(el as HTMLElement).style.cursor = 'grabbing'
    }

    this.dm = 0
    this.pos = {
      // The current scroll
      left: this.el.scrollLeft,
      top: this.el.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    }

    document.addEventListener('mousemove', this.mouseMove)
    document.addEventListener('mouseup', this.mouseUp)
    document.addEventListener('click', this.mouseClick)
  }

  private mouseMove(e: MouseEvent) {
    const dx = e.clientX - this.pos.x
    const dy = e.clientY - this.pos.y
    const { scrollTop, scrollLeft } = this.el
    // Scroll element
    this.el.scrollTop = this.pos.top - dy
    this.el.scrollLeft = this.pos.left - dx
    // Get relative scroll
    this.dm +=
      Math.abs(scrollTop - this.el.scrollTop) +
      Math.abs(scrollLeft - this.el.scrollLeft)
  }

  public detach() {
    this.el.removeEventListener('mousedown', this.mouseDown)
    this.el.removeEventListener('mouseup', this.mouseUp)
    this.el.removeEventListener('mousemove', this.mouseMove)
    this.el.removeEventListener('click', this.mouseClick)
  }
}

export const handlers = draggable.map(el => new DraggableHandler(el))
