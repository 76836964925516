const languageSwitch = document.querySelector<HTMLElement>(
  '.js-language-switch',
)

function getCurrentLang() {
  return (languageSwitch && languageSwitch.dataset.currentLang) || 'en'
}

function formatCurrency(value: number) {
  const lang = getCurrentLang()

  let valueStr = ''

  if (lang === 'nb') {
    const n = new Number(Math.round(value * 2) / 2)
    valueStr = n.toLocaleString(lang, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else {
    const n = new Number(value)
    valueStr = n.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  if (valueStr.endsWith('00')) {
    valueStr = valueStr.slice(0, -3)
  }

  return valueStr
}

const toggles = Array.from<HTMLInputElement>(
  document.querySelectorAll('input.js-pricing-switch'),
)

const jsPricing = Array.from<HTMLElement>(
  document.querySelectorAll('.js-pricing'),
)

for (const pricing of jsPricing) {
  pricing.dataset.original = pricing.innerText.replaceAll(',', '.')
}

const jsPricingLink = Array.from<HTMLAnchorElement>(
  document.querySelectorAll('a.js-pricing-url'),
)

const jsPricingHide = Array.from<HTMLElement>(
  document.querySelectorAll('.js-pricing-hide'),
)

function updateUrl(monthly: boolean) {
  const url = new URL(window.location.href)

  if (monthly) {
    url.searchParams.set('interval', 'monthly')
  } else {
    url.searchParams.delete('interval')
  }
  window.history.replaceState(undefined, '', url.toString())
}

function setChecked(checked: boolean) {
  if (checked) {
    for (const price of jsPricing) {
      price.innerText = price.dataset.original || ''
    }
  } else {
    for (const price of jsPricing) {
      const newPrice = formatCurrency(
        parseFloat(price.dataset.original || '') / 1.2,
      )
      price.innerText = newPrice
    }
  }

  for (const link of jsPricingLink) {
    const href = link.getAttribute('href') || '#'

    if (href.startsWith('#')) {
      continue
    }

    const base = href.startsWith('/') ? window.location.origin : undefined
    const url = new URL(href, base)
    const isSignup = url.pathname.split('/').includes('signup')

    if (checked) {
      if (isSignup) {
        url.searchParams.delete('interval')
      } else {
        url.searchParams.set('interval', 'monthly')
      }
    } else {
      if (isSignup) {
        url.searchParams.set('interval', 'yearly')
      } else {
        url.searchParams.delete('interval')
      }
    }

    const newUrl = base
      ? [url.pathname, url.searchParams.toString()].filter(v => v).join('?') +
        url.hash
      : url.toString()

    link.setAttribute('href', newUrl)

    for (const toggle of toggles) {
      toggle.checked = checked
    }
  }

  for (const el of jsPricingHide) {
    el.style.visibility = checked ? 'hidden' : ''
  }

  updateUrl(checked)
}

function changeHandler(e: Event) {
  if (!e.currentTarget) {
    return
  }
  const target: HTMLInputElement = e.currentTarget as HTMLInputElement

  setChecked(target.checked)
}

function init() {
  if (!toggles.length) {
    return
  }

  for (const toggle of toggles) {
    toggle.addEventListener('change', changeHandler)
  }

  const url = new URL(window.location.href)
  setChecked(url.searchParams.get('interval') === 'monthly')
}

init()
