export async function loadFonts() {
  // Font loading
  let fontDisplaySupport = false
  try {
    const e = document.createElement('style')
    e.textContent = '@font-face { font-display: swap; }'
    document.documentElement.appendChild(e)
    fontDisplaySupport =
      e.sheet?.cssRules[0].cssText.indexOf('font-display') !== -1
    e.remove()
  } catch (ex) {
    // Do nothing
  }

  if (!fontDisplaySupport) {
    await Promise.all([
      new FontFace(
        'Larsseit',
        'url("/fonts/32129D_0_0.woff2") format("woff2"), url("/fonts/32129D_0_0.woff") format("woff")',
        {
          weight: '300',
        },
      ),
      new FontFace(
        'Larsseit',
        'url("/fonts/32129D_1_0.woff2") format("woff2"), url("/fonts/32129D_1_0.woff") format("woff")',
        {
          weight: '400',
        },
      ),
      new FontFace(
        'Larsseit',
        'url("/fonts/3225EF_0_0.woff2") format("woff2"), url("/fonts/3225EF_0_0.woff") format("woff")',
        {
          weight: '500',
        },
      ),
    ]).catch(err => console.log(err))
  }
  document.documentElement.classList.add('wf-loaded')
}
