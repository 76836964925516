import '../scss/style.scss'
// import '../stylus/style.styl'
import { setupCarousel } from './carousel'
import { setupHamburger } from './hamburger'
import { setupFrontpageImageRow, setupPhotoImageRow } from './imageRows'
import { setupLanguageSwitcher } from './languageSwitch'
import { loadFonts } from './loadFonts'
import { setupPhotoPromo } from './photoPromo'
import { scrollToButtons, scrollToPricing } from './scrollTo'
import './pricingSwitch'
import './draggableScroll'
// import '../stylus/style.styl'
// import '../stylus/style.styl'
// import '../scss/style.scss'

const isPhotosPage = document.location.pathname.includes('photos')

scrollToButtons()
scrollToPricing()

if (isPhotosPage) {
  // console.log('Photo image row')
  setupPhotoImageRow()
} else {
  // console.log('Frontpage image row')
  setupFrontpageImageRow()
}
loadFonts()
setupLanguageSwitcher()
setupPhotoPromo()
setupHamburger()
setupCarousel()
