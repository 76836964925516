export interface ImageRowImage {
  src: string
  srcset: string
}
export class ImageRow {
  public imageSet: ImageRowImage[]
  public placeholders: HTMLElement[]
  public sizesAttr: string
  constructor(options: {
    sizes: {
      default: number
      small: number
    }
    sizesAttr: string
  }) {
    const basePath = '/resources/images/carousel/'

    this.imageSet = []
    let index
    for (index = 1; index <= 52; index++) {
      this.imageSet.push({
        src: `${basePath}${index}-${options.sizes.default}.jpg`,
        srcset: `${basePath}${index}-${options.sizes.small}.jpg ${
          options.sizes.small
        }w, ${basePath}${index}-${options.sizes.small * 2}.jpg ${
          options.sizes.small * 2
        }w, ${basePath}${index}-${options.sizes.default}.jpg ${
          options.sizes.default
        }w, ${basePath}${index}-${options.sizes.default * 2}.jpg ${
          options.sizes.default * 2
        }w`,
      })
    }
    this.placeholders = this.shuffleArray(
      Array.from(document.querySelectorAll('.image-row__block')),
    )
    this.sizesAttr = options.sizesAttr
  }

  init() {
    this.imageSet = this.shuffleArray(this.imageSet)
    this.placeholders.forEach((placeholder, index) => {
      const image = this.createImage(this.imageSet[index])
      this.loadImage(placeholder, image)
    })
  }

  private loadImage(placeholder: HTMLElement, image: HTMLImageElement) {
    const onImageLoad = () => {
      placeholder.classList.add('is-loaded')
      image.removeEventListener('load', onImageLoad)
    }

    placeholder.appendChild(image)
    requestAnimationFrame(() => {
      if (image.complete) {
        placeholder.classList.add('is-loaded')
      } else {
        image.addEventListener('load', onImageLoad)
      }
    })
  }

  private createImage(image: ImageRowImage) {
    const imageElement = document.createElement('img')

    imageElement.src = image.src
    imageElement.srcset = image.srcset
    imageElement.sizes = this.sizesAttr
    imageElement.className = 'image-row__image'
    imageElement.alt = ''

    return imageElement
  }

  private shuffleArray<T>(arr: T[]): T[] {
    let j
    let x
    let i
    for (i = arr.length; i; i--) {
      j = Math.floor(Math.random() * i)
      x = arr[i - 1]
      arr[i - 1] = arr[j]
      arr[j] = x
    }
    return arr
  }
}
